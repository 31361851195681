<template>
  <div class="flex flex-col">
    <div class="flex flex-col p-4 border rounded">
      <div class="flex space-x-2">
        <input
          type="text"
          v-model="$v.personal.first_name.$model"
          placeholder="Vorname"
          :class="{ 'border-red-500': $v.personal.first_name.$error }"
          class="my-2 border rounded px-4 py-2 w-1/2 text-xs appearance-none focus:outline-none bg-transparent"
        />
        <input
          type="text"
          v-model="$v.personal.last_name.$model"
          placeholder="Nachname"
          :class="{ 'border-red-500': $v.personal.last_name.$error }"
          class="my-2 border rounded px-4 py-2 w-1/2 text-xs appearance-none focus:outline-none bg-transparent"
        />
      </div>
      <div class="flex space-x-2">
        <date-picker
          v-model="$v.personal.birth_date.$model"
          :input-class="{
            'border-red-500': $v.personal.birth_date.$error,
            'my-2 border rounded px-4 py-2 text-xs appearance-none focus:outline-none w-full': true,
          }"
          placeholder="Geburtsdatum"
        />

        <date-picker
          v-model="$v.personal.start_date.$model"
          :input-class="{
            'border-red-500': $v.personal.start_date.$error,
            'my-2 border rounded px-4 py-2 text-xs appearance-none focus:outline-none w-full': true,
          }"
          placeholder="Eintrittsdatum"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { createOne } from '@/services/personal'

const tempPersonal = {
  first_name: null,
  last_name: null,
  birth_date: null,
  start_date: null,
}

export default {
  name: 'new-personal',
  props: ['saved'],
  components: {},
  data() {
    return {
      personal: {
        ...tempPersonal,
      },
    }
  },
  validations: {
    personal: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      birth_date: {
        required,
      },
      start_date: {
        required,
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {},
    save() {
      this.$v.personal.$touch()

      if (!this.$v.$invalid) {
        createOne(this.personal).then(response => {
          if (response.status === 201) {
            this.$router.push(`/personal/${response.data.data.id}`)
          } else {
            this.$toast.error('Error while updating.')
          }

          this.$emit('close-modal', { show: false })
        })
      }
    },
  },
  watch: {
    saved: function(n) {
      if (n) this.personal = { ...this.tempPersonal }
    },
  },
}
</script>
